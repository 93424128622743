<template>
  <div>
    <section id="features" class="section-padding">
      <div class="container">
        <div class="blog-head text-center">
          <h6>My Services</h6>
          <h2>Empowering Businesses with Customized Software Solutions.</h2>
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <div class="content-left">
              <div class="box-item animated wow fadeInLeft">
                <span class="icon">
                  <img src="/service/7.png" />
                </span>
                <div class="text">
                  <h4>Application Development</h4>
                  <p>
                    Developing and Deploying Mobile Applications for Android and
                    IOS
                  </p>
                </div>
              </div>
              <div
                class="box-item animated wow fadeInLeft"
                data-wow-delay="0.6s"
              >
                <span class="icon">
                  <img src="/service/2.png" />
                </span>
                <div class="text">
                  <h4>Web Application Development</h4>
                  <p>
                    Designing & Developing High-performance Web Applications
                  </p>
                </div>
              </div>
              <div
                class="box-item animated wow fadeInLeft"
                data-wow-delay="0.9s"
              >
                <span class="icon">
                  <img src="/service/1.png" />
                </span>
                <div class="text">
                  <h4>Software & App Deployment</h4>
                  <p>
                    Efficient Deployment for your software and mobile
                    applications
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <div class="show-box animated wow fadeInUp">
              <img
                src="@/assets/image/Webdevices.svg"
                height="100%"
                width="100%"
                alt="service"
              />
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <div class="content-right">
              <div class="box-item animated wow fadeInRight">
                <span class="icon">
                  <img src="/service/4.png" />
                </span>
                <div class="text" style="text-align: start">
                  <h4 style="text-align: start">Software Consulting</h4>
                  <p>
                    Delivering Expert Software Consulting Services for Optimal
                    Solutions
                  </p>
                </div>
              </div>
              <div
                class="box-item animated wow fadeInRight"
                data-wow-delay="0.6s"
              >
                <span class="icon">
                  <img src="/service/5.png" />
                </span>
                <div class="text" style="text-align: start">
                  <h4>AI & Robotics Solution</h4>
                  <p>AI Chatbots & Model Development and Robotics Solutions</p>
                </div>
              </div>
              <div
                class="box-item animated wow fadeInRight"
                data-wow-delay="0.9s"
              >
                <span class="icon">
                  <img src="/service/6.png" />
                </span>
                <div class="text" style="text-align: start">
                  <h4>Software Maintenance & Support</h4>
                  <p>
                    Performance monitoring, proactive optimization and fast
                    issue resolution.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "service-page",
};
</script>

<style lang="scss" scoped>
section {
  padding: 60px 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#features {
}

#features .icon {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 4px;
  text-align: center;
  position: relative;
  z-index: 1;
}

#features .content-left span {
  float: right;
}

#features .content-left .text {
  text-align: right;
}

#features .content-right span {
  float: left;
}

#features .box-item {
  border: 2px solid #013747;
  padding: 15px;
  line-height: 22px;
  margin-top: 30px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.25);
  -webkit-transition: all 0.3s ease-in-out;
  -mox-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  top: 0;
}

#features .box-item .icon {
  text-align: center;
  margin: 12px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#features .box-item .icon {
  color: #008de2;
  font-size: 30px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#features .box-item .text h4 {
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #0794c9;
}

#features .box-item .text p {
  font-size: 14px;
  line-height: 26px;
  color: #fff;
}

#features .box-item:hover {
  border-radius: 15px;
  box-shadow: 0 0 12px #013747;
}

#features .box-item:hover h4 {
  color: #0794c9;
}

#features .show-box {
  margin-top: 50px;
}

#features .show-box img {
  width: 100%;
}
.heading {
  font-size: 15px;
  font-weight: 700;
  margin: 0px;
  padding: 0;
  color: #fff;
}
.heading .sub-title {
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  margin: -30px 10px 20px;
  padding: 0;
}
.service-title h3 {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  font-size: 20px;
  display: inline-block;
  padding: 15px 20px;
  position: relative;
  margin-top: -50px;
  margin-bottom: 20px;
}
.service-title {
  margin-bottom: 15px;
}
.service-title h3 span {
  color: #6acde4;
}
.service-title h3:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #6acde4;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.service-title h3:hover:after {
  width: 100%;
  left: 0;
}
.blog-head {
  margin-bottom: 5vh;
}

.blog-head h6 {
  color: #0794c9;
  position: relative;
  display: inline-block;
  text-transform: capitalize;
  font-size: 26px;
  padding: 1rem;
}
.blog-head h2 {
  color: #f6b50e;
  font-size: 17px;
}

.blog-head h6:after,
.blog-head h6:before {
  position: absolute;
  content: "";
  width: 50%;
  height: 3px;
  background: #0794c9;
  top: 50%;
}

.blog-head h6:after {
  right: 100%;
}

.blog-head h6:before {
  left: 100%;
}
</style>