import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Test from "../views/Test";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
  },
  {
    path: "/linkedin",
    beforeEnter() {
      location.href =
        "https://www.linkedin.com/in/bethelehem-mulugeta-0506a21a3/";
    },
  },
  {
    path: "/upwork",
    beforeEnter() {
      location.href = "https://www.upwork.com/freelancers/~01341db4af73961f34";
    },
  },
  {
    path: "/github",
    beforeEnter() {
      location.href = "https://github.com/bethlehem-dagnachew/";
    },
  },
  {
    path: "/tenawoDoctor",
    beforeEnter() {
      location.href =
        "https://play.google.com/store/apps/details?id=host.expo.tenawoVendor1";
    },
  },
  {
    path: "/tenawoClient",
    beforeEnter() {
      location.href =
        "https://play.google.com/store/apps/details?id=com.kmstenawo.app";
    },
  },
  {
    path: "/thw",
    beforeEnter() {
      location.href =
        "https://play.google.com/store/apps/details?id=com.thw.app";
    },
  },
  {
    path: "/scitechvalley",
    beforeEnter() {
      location.href = "https://www.scitechvalley.com";
    },
  },
  {
    path: "/thwweb",
    beforeEnter() {
      location.href = "https://ethio.thehabeshaweb.com/";
    },
  },
  {
    path: "/welland",
    beforeEnter() {
      location.href = "https://wellandplastic.com/";
    },
  },
  {
    path: "/tenawoweb",
    beforeEnter() {
      location.href = "https://tenawo.com";
    },
  },
  {
    path: "/ednadental",
    beforeEnter() {
      location.href = "https://ednadent.com";
    },
  },
  {
    path: "/zuns",
    beforeEnter() {
      location.href = "https://zuns-buisness.web.app";
    },
  },
  {
    path: "/crtec",
    beforeEnter() {
      location.href = "https://crtecsc.com";
    },
  },
  {
    path: "/edemy",
    beforeEnter() {
      location.href = "https://ethiodemy.com/";
    },
  },
  {
    path: "/visionzero",
    beforeEnter() {
      location.href = "https://kenamenged.com";
    },
  },
  {
    path: "/bluenile",
    beforeEnter() {
      location.href = "https://bluenileeu.com";
    },
  },
  {
    path: "/ethiomind",
    beforeEnter() {
      location.href = "https://ethiomind.net";
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
