<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="headerr active">
          <h1>Work <strong>Experience</strong></h1>
          <h5>
            I'm a Computer Engineer with over 4 years of experience working for
            various international companies.
          </h5>
          <div class="cache"></div>
        </div>
        <div class="rightbox">
          <div class="rb-container">
            <ul class="rb">
              <li class="rb-item" ng-repeat="itembx">
                <div class="experience-page">
                  <div class="timestamp">Jan 2021- Present</div>
                  <div class="timestampp-exper">Lead Software Developer</div>
                  <div class="item-titlee">
                    <span>SciTech Valley</span> @Addis Ababa, Ethiopia
                  </div>
                  <div class="item-title">
                    <p>- Oversee and Lead Software development Team</p>
                    <p>- Design, Develop and Maintain Software Projects</p>
                    <p>
                      - Oversee the Development & Delivery of Software Products
                    </p>
                    <p>- Technology Stack Selection</p>
                  </div>
                </div>
              </li>

              <li class="rb-item" ng-repeat="itembx">
                <div class="experience-page">
                  <div class="timestamp">Oct 2023 - Present</div>
                  <div class="timestampp-exper">Lead Software Developer</div>
                  <div class="item-titlee">
                    <span>Trak Tel</span> @Las Vegas, America
                  </div>
                  <div class="item-title">
                    <p>- Designed and Developed CRM System</p>
                    <p>- Created a robust real-time chat system</p>
                    <p>- Integrate a third-party services and APIs</p>
                    <p>- Conducted unit, integration, & system testing</p>
                  </div>
                </div>
              </li>

              <li class="rb-item" ng-repeat="itembx">
                <div class="experience-page">
                  <div class="timestamp">May 2023 - Nov 2023</div>
                  <div class="timestampp-exper">
                    Mobile Application Developer
                  </div>
                  <div class="item-titlee">
                    <span>Poko</span> @Bern, Berne, Switzerland
                  </div>
                  <div class="item-title">
                    <p>- Developed E-learning Mobile Application</p>
                    <p>- Conducted Unit, Integration, & System Testing</p>
                    <p>- Integrated third-party websites and APIs</p>
                    <p>- Implemented API integration with the backend</p>
                  </div>
                </div>
              </li>

              <li class="rb-item" ng-repeat="itembx">
                <div class="experience-page">
                  <div class="timestamp">Dec 2021 - Jan 29 2023</div>
                  <div class="timestampp-exper">
                    Project Manager & Mobile App Developer
                  </div>
                  <div class="item-titlee">
                    <span>The Habesha Web</span> @Dallas, Texas, United States
                  </div>
                  <div class="item-title">
                    <p>- Manage the Software Development Team</p>
                    <p>- Develop Android and IOS Mobile Applications</p>
                    <p>- Design, Develop, and Test E-Commerce Platforms</p>
                    <p>
                      - Integrate Third-Party APIs and Services into Mobile Apps
                    </p>
                    <p>- Deploy Mobile Apps on the Play Store & App Store</p>
                  </div>
                </div>
              </li>
              <li class="rb-item" ng-repeat="itembx">
                <div class="experience-page">
                  <div class="timestamp">Jul 2021 - Mar 2023</div>
                  <div class="timestampp-exper">
                    FullStack Developer & Mobile App Developer
                  </div>
                  <div class="item-titlee">
                    <span>KMS ETH Health Trading S.C</span> @Addis Ababa,
                    Ethiopia
                  </div>
                  <div class="item-title">
                    <p>
                      - Developed responsive web apps using modern frameworks.
                    </p>

                    <p>- Created native and hybrid mobile applications</p>
                    <p>
                      - Conducted unit and integration tests to ensure app
                      stability
                    </p>
                    <p>
                      - Deployed mobile apps to both Play Store and App Store
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="headerr active">
          <h1><strong>Education</strong></h1>
          <h5>
            I had the opportunity to pursue BSC and MSC degree in Computer
            Engineering.
          </h5>
          <div class="cache"></div>
        </div>
        <div class="rightbox">
          <div class="rb-container">
            <ul class="rb">
              <li class="rb-item" ng-repeat="itembx">
                <div class="experience-page">
                  <div class="timestamp">SEP 21 2021</div>
                  <div class="timestampp">
                    Addis Ababa Science and Technology University
                  </div>
                  <div class="item-title">
                    Bachelor's Degree In Electrical and Computer Engineering
                  </div>
                </div>
              </li>
              <li class="rb-item" ng-repeat="itembx">
                <div class="experience-page">
                  <div class="timestamp">SEP 1 2022</div>
                  <div class="timestampp">
                    Addis Ababa Science and Technology University
                  </div>
                  <div class="item-title">
                    Masters of Sciences in Computer Engineering
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import BubbleView from "./BubbleView.vue";
export default {
  name: "experience-page",
  components: {
    // BubbleView,
  },
};
</script>

<style lang="scss" scoped>
.rb-container ul.rb li {
  list-style: none;
  margin: auto;
  margin-left: 1em;
  height: 100%;
  border-left: 1px dashed #fff;
  padding: 0 0 20px 20px;
  position: relative;
}

.rb-container ul.rb li:last-child {
  border-left: 0;
}

.rb-container ul.rb li::before {
  position: absolute;
  left: -18px;
  top: -5px;
  content: " ";
  border: 8px solid rgba(255, 255, 255, 1);
  border-radius: 500%;
  background: #f6b50e;
  height: 30px;
  width: 30px;
  transition: all 500ms ease-in-out;
}

.rb-container ul.rb li:hover::before {
  border-color: #232931;
  transition: all 1000ms ease-in-out;
}
.experience-page {
  border-style: dotted;
  border-color: #0794c9;
  padding: 10px;
}

ul.rb li .timestamp {
  color: #0794c9;
  position: relative;
  width: 100%;
  font-size: 12px;
  text-align: start;
}
ul.rb li .timestampp {
  color: #f6b50e;
  width: 100%;
  font-size: 13px;
  text-align: start;
}
ul.rb li .timestampp-exper {
  color: #f6b50e;
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  text-align: start;
}
.item-titlee {
  color: #ffff;
  text-align: start;
}
.item-titlee span {
  color: #0794c9;
  text-align: start;
}
.item-title {
  color: #fff;
  line-height: 0.7rem;
  text-align: start;
}
.item-title p {
  color: #f2f2f2;
  margin-top: 10px;
}
.item-location {
  font-size: 12px;
  text-align: start;
  color: #f2f2f2;
}

.container-3 {
  width: 5em;
  vertical-align: right;
  white-space: nowrap;
  position: absolute;
}

.container-3 .icon {
  margin: 1.3em 3em 0 31.5em;
  position: absolute;
  width: 150px;
  height: 30px;
  z-index: 1;
  color: #4f5b66;
}
.banner {
  height: auto;
  margin: 32px 0px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1;
  @media (min-width: 768px) {
    height: 450px;
    margin-bottom: 32px;
  }
  .banner-imageee {
    position: absolute;
    opacity: 0.25;
    right: 0px;
    top: 0px;
    height: 200px;
    z-index: -1;
    -webkit-filter: drop-shadow(0 3rem 0.05rem rgba(191, 216, 255, 1));
    filter: drop-shadow(0 3rem 0.05rem rgba(191, 216, 255, 1));
    @media (min-width: 768px) {
      display: inherit;
      height: 400px;
      top: 0px;
      opacity: 0.5;
    }
    @media (min-width: 992px) {
      height: 500px;
      top: -50px;
      opacity: 1;
    }
  }
}
@media screen and (max-width: 767px) {
  .item-title {
    line-height: 1.2rem;
  }
}

.headerr {
  position: relative;
  left: 55%;
  margin-top: 10vh;
  margin-bottom: 1vh;

  transform: translate(-50%, -50%);
  color: #0794c9;
  font-family: Helvetica;
  font-size: 26px;
}
.headerr .cache {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.8s ease-out 0s;
}
.headerr.active .cache {
  transition: all 0.8s ease-out 0.3s;
  left: -100%;
}
.cache:before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 1%;
  height: 0;
  transition: all 0.3s ease-out 0.8s;
  background-color: #f6b50e;
}
.headerr.active .cache:before {
  height: 100%;
  transition: all 0.3s ease-out 0s;
}
.headerr h1 {
  font-weight: 10;
  margin: 0;
  font-size: 26px;
  text-align: start;
  padding: 5px 0px 5px 10px;
}
.headerr h5 {
  margin: 0;
  width: 90%;
  color: #fff;
  font-size: 15px;
  text-align: start;
  padding: 0px 0px 0px 10px;
}
</style>