<template>
  <div id="home">
    <!-- <Landing/> -->
    <!-- <Navbar/> -->
    <header class="header" id="header">
      <nav class="nav containerrr-navb">
        <img class="nav__logo" src="@/assets/image/bethlogo.png" alt="logo" />
        <div class="nav__menu" id="nav-menu">
          <ul class="nav__list">
            <li class="nav__item">
              <router-link to="/" class="nav__link">
                <!--active-link-->
                <i class="fa fa-home"></i>
                <span class="nav__name" @click="scrollToHero">Home</span>
              </router-link>
            </li>
            <li class="nav__item">
              <router-link to="#" class="nav__link">
                <i class="fa fa-list-alt" aria-hidden="true"></i>
                <span class="nav__name" @click="scrollToAbout">About</span>
              </router-link>
            </li>

            <li class="nav__item">
              <router-link to="#" class="nav__link">
                <i class="fa fa-user"></i>
                <span class="nav__name" @click="scrollToService">Service</span>
              </router-link>
            </li>
            <li class="nav__item">
              <router-link to="#" class="nav__link">
                <i class="fa fa-user"></i>
                <span class="nav__name" @click="scrollToResume">Resume</span>
              </router-link>
            </li>

            <li class="nav__item">
              <router-link to="#" class="nav__link">
                <i class="fa fa-address-book" aria-hidden="true"></i>
                <span class="nav__name" @click="scrollToPortifolo"
                  >Portifolo</span
                >
              </router-link>
            </li>
            <li class="nav__item">
              <router-link to="#" class="nav__link">
                <i class="fa fa-address-book" aria-hidden="true"></i>
                <span class="nav__name" @click="scrollToContact">Contact</span>
              </router-link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
    <br /><br />
    <Landing2 ref="hero" />
    <About ref="about" />
    <Skill ref="skill" />
    <Service ref="service" />
    <Experience ref="experience" />

    <Project ref="project" />
    <Certifcation />
    <!-- <Testimonials /> -->
    <Footer ref="contact" />
  </div>
</template>

<script>
// import Navbar from '@/components/Navbar.vue';
import Service from "@/components/Service.vue";
// import Landing from '@/components/Landing.vue';
// import Price from '@/components/Price.vue';
import Experience from "@/components/Experience.vue";
import Certifcation from "@/components/Certifcation.vue";
import Skill from "@/components/Skill.vue";
import Footer from "@/components/Footer.vue";
import About from "@/components/About.vue";
import Landing2 from "@/components/Landing2.vue";
import Project from "@/components/Project.vue";
// import Testimonials from "@/components/Testimonials.vue";
export default {
  name: "home-page",
  data() {
    return {
      pageTitle: "Bethelehem Mulugeta ",
      pageDescription:
        "Discover my software development portfolio, showcasing expertise in web and mobile app development. As a seasoned software developer, I specialize in crafting responsive and high-performing applications using modern technologies like Vue.js, Ionic, Nuxt js, React.js, Angular.js, and more. Explore a range of projects that reflect my commitment to clean code, exceptional user experiences, and innovative problem-solving. Explore my work and let's collaborate on your next software project. I'm ready to turn your vision into a reality. Contact me to discuss how I can bring value to your software development endeavors.",
      pageKeywords:
        "Software Development, Web Development, Mobile App Development, Full-Stack Development, Front-End Development, Back-End Development, UI/UX Design, Responsive Web Design, JavaScript, HTML5, CSS3, Vue.js, React.js, Angular, PHP, Laravel, Mobile App Design, Android App Development, iOS App Development, Cross-Platform Development, Database Design, API Development, Cloud Computing, DevOps, Agile Development, Test-Driven Development (TDD), Version Control (Git), Continuous Integration (CI/CD), Software Architecture, Code Optimization, Code Security, Project Management, Agile Methodologies, Bethlehem, Bethelehem, Bethelehem Mulugeta, developer, software developer, portifolo",
    };
  },
  metaInfo() {
    return {
      title: this.pageTitle,
      meta: [
        {
          name: "description",
          content: this.pageDescription,
        },
        {
          name: "keywords",
          content: this.pageKeywords,
        },
      ],
    };
  },
  components: {
    Service,
    // Navbar,
    Certifcation,
    Experience,
    // Landing,
    // Testimonials,
    Project,
    Skill,
    Footer,
    About,
    Landing2,
  },
  mounted() {
    /*=============== SCROLL SECTIONS ACTIVE LINK ===============*/
    const sections = document.querySelectorAll("section[id]");

    function scrollActive() {
      const scrollY = window.pageYOffset;

      sections.forEach((current) => {
        const sectionHeight = current.offsetHeight,
          sectionTop = current.offsetTop - 50,
          sectionId = current.getAttribute("id");

        const navLink = document.querySelector(
          ".nav__menu a[href*=" + sectionId + "]"
        );

        if (navLink) {
          if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
            navLink.classList.add("active-link");
          } else {
            navLink.classList.remove("active-link");
          }
        }
      });
    }

    window.addEventListener("scroll", scrollActive);

    /*=============== CHANGE BACKGROUND HEADER ===============*/
    function scrollHeader() {
      const header = document.getElementById("header");
      // When the scroll is greater than 80 viewport height, add the scroll-header class to the header tag
      if (this.scrollY >= 80) header.classList.add("scroll-header");
      else header.classList.remove("scroll-header");
    }
    window.addEventListener("scroll", scrollHeader);
  },
  methods: {
    scrollToAbout() {
      this.$refs["about"].$el.scrollIntoView({ behavior: "smooth" });
    },
    scrollToHero() {
      this.$refs["hero"].$el.scrollIntoView({ behavior: "smooth" });
    },
    scrollToService() {
      this.$refs["service"].$el.scrollIntoView({ behavior: "smooth" });
    },
    scrollToResume() {
      this.$refs["experience"].$el.scrollIntoView({ behavior: "smooth" });
    },
    scrollToPortifolo() {
      this.$refs["project"].$el.scrollIntoView({ behavior: "smooth" });
    },
    scrollToContact() {
      this.$refs["contact"].$el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url(@/assets/css/nav.css);
</style>