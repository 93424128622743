<template>
  <div>
    <div id="portfolio">
      <div class="container">
        <div class="blog-head text-center">
          <h6>Recent Projects</h6>
          <h2>Projects that speak for themselves</h2>
        </div>
        <div class="row">
          <div
            class="portfolio-col"
            v-for="(project, index) in projects"
            :key="index"
          >
            <div
              class="project-image"
              :style="`background-image: url('${project.imageSrc}'); background-position: center; background-size: cover;`"
              :title="project.title"
              loading="lazy"
            ></div>
            <!-- <img
              :src="project.imageSrc"
              class="obeject-fit"
              alt="project image"
              loading="lazy"
            /> -->
            <div class="layer">
              <h3>{{ project.title }}</h3>
              <p>{{ project.description }}</p>
              <router-link :to="project.link" target="_blank">
                <div class="iocon-icon">
                  <img src="@/assets/image/view.png" alt="project image" />
                </div>
              </router-link>
              <!-- <router-link to="/tenawoDoctor" target="_blank">
                <div class="iocon-icon">
                  <img src="@/assets/image/view.png" alt="project image" />
                </div>
              </router-link> -->
            </div>
          </div>
          <!-- service 2 -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home-page",
  data() {
    return {
      projects: [
        {
          title: "Tenawo Doctor",
          description:
            "Tenawo Doctor is a Doctor Management mobile application. The App allows doctors to manage their patients, appointment, schedules, and patient data.",
          imageSrc: require("@/assets/image/service/tenawoDoctor.png"),
          link: "/tenawoDoctor",
        },
        {
          title: "Tenawo Client",
          description:
            "The app connect health providers and seekers. The app contains more than 13 services including hospital, Clinic, home care booking and medical wholesaling and ambulance ordering",
          imageSrc: require("@/assets/image/service/tenawoclient.png"),

          link: "/tenawoClient",
        },
        {
          title: "HealthPath Doctor Management",
          description:
            "Tenawo Doctor is a Doctor Management mobile application. The App allows doctors to manage their patients, appointment, schedules, and patient data.",
          imageSrc: require("@/assets/image/service/healthpath-1.jpg"),
          link: "",
        },
        {
          title: "The Habesha Web E-Commerce",
          description:
            "E-commerce platform that allows users to buy and sell products easily. the platform contains more than 14 categories and subcategories.",
          imageSrc: require("@/assets/image/service/thw.png"),
          link: "#",
        },
        // Add more project objects here...
        {
          title: "Scitech Valley Website",
          description:
            "I manage to develop a website for scitech Valley. the website includes a landing, about, service, expertise, process, and contact page.",
          imageSrc: require("@/assets/image/service/sci.png"),
          link: "/scitechvalley",
        },
        {
          title: "The Habesha Web Website",
          description:
            "An E-commerce website which allow users to buy and sell different products. The website includes different categories and features.",
          imageSrc: require("@/assets/image/service/thw2.png"),
          link: "",
        },
        {
          title: "Welland Plastics",
          description:
            "The website is mainly used to display and promote products that the company produce. The website contain Landing, About us, Testimonial, Product, Partners and Contact pages.",
          imageSrc: require("@/assets/image/service/welland.png"),
          link: "/welland",
        },
        {
          title: "Tenawo Website",
          description:
            "It is multi-user web app which includes Pharmacy, laboratory, diagnostic, diagnostic imaging, private hospital, doctors appointments and many more",
          imageSrc: require("@/assets/image/service/tenawoweb.png"),
          link: "/tenawoweb",
        },
        {
          title: "Edna Dental Clinic",
          description:
            "A website which include about us, services and treatment, gallery and contact us page. the website is mainly developed to promote the company service and profile.",
          imageSrc: require("@/assets/image/service/edna.png"),
          link: "/ednadental",
        },
        {
          title: "CRTEC Website",
          description:
            "The website includes our project, news and update, service, About and contact page. The website allow the company to promote their projects and add news and updates.",
          imageSrc: require("@/assets/image/service/crtech.png"),
          link: "/crtec",
        },
        {
          title: "Blue Nile E-commerce",
          description:
            "An E-commerce website that includes different categories which allow users to buy different products online. It also allows users to buy using their pay pal account.",
          imageSrc: require("@/assets/image/service/bluenile.png"),
          link: "/bluenile",
        },
        {
          title: "Ethiodemy",
          description:
            "Online learning platform that providing different educational coarses. The website allow users to purchase and learn different coarses.",
          imageSrc: require("@/assets/image/service/edemy.png"),
          link: "/edemy",
        },
        {
          title: "Zuns Group Website",
          description:
            "A website which aim to promote the company product and service. The web include about, service, group, product, and contact pages.",
          imageSrc: require("@/assets/image/service/zuns.png"),
          link: "/zuns",
        },
        {
          title: "E-Mind Set E-Learning",
          description:
            "A website for all ages. Learn, grow, and thrive with educational courses designed to inspire and empower. From kids to adults, discover a wide range of topics.",
          imageSrc: require("@/assets/image/service/e-mind.png"),
          link: "/ethiomind",
        },
        {
          title: "Kena Menged",
          description:
            "A website dedicated to promoting safety on the roads and in transportation. It provides valuable resources and information aimed at reducing accidents and ensuring safer journeys for everyone.",
          imageSrc: require("@/assets/image/service/visionzero.png"),
          link: "/visionzero",
        },
        {
          title: "DocuSign Clone",
          description:
            " A project involving the replication of DocuSign's functionality. This project aimed to create a digital document signing solution, allowing users to sign both DOCX and PDF files digitally",
          imageSrc: require("@/assets/image/service/docusign.png"),
          link: "",
        },
      ],
    };
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
/* ------------------- Portfolio ------------------- */
h2 {
  color: #fff;
}
#portfolio .row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 40px;
}

.portfolio-col {
  border-radius: 17px;
  position: relative;
  overflow: hidden;
}

.portfolio-col .project-image {
  width: 100%;
  height: 47vh;
  border-radius: 17px;

  transition: transform 0.5s;
}

.portfolio-col:hover .project-image {
  transform: scale(1.2);
}

#portfolio .layer {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    0deg,
    #6acde4 0%,
    rgba(38, 38, 38, 0.644750702247191) 100%
  );
  border-radius: 17px;
  padding: 0 40px;
  text-align: center;
  overflow: hidden;
  transition: height 0.5s;
}

#portfolio .layer h3 {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  width: 100%;
}

#portfolio .layer p {
  font-size: 15px;
  margin-top: 25px;
  color: #fff;
}

#portfolio .layer .iocon-icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: white;
  color: var(--primary-color);
  font-size: 17px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
#portfolio .layer .iocon-icon img {
  height: 20px;
  width: 20px;
}

#portfolio .portfolio-col:hover .layer {
  height: 100%;
}

/* ------------------- CSS For Mobile Screens ------------------- */

@media screen and (max-width: 890px) {
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 890px) {
}

@media screen and (max-width: 502px) {
}

@media screen and (max-width: 387px) {
}

@media screen and (max-width: 364px) {
}
.blog-head h6 {
  color: #0794c9;
  position: relative;
  display: inline-block;
  text-transform: capitalize;
  font-size: 26px;
}
.blog-head h2 {
  color: #f6b50e;
  font-size: 17px;
}

.blog-head h6:after,
.blog-head h6:before {
  position: absolute;
  content: "";
  width: 30px;
  height: 3px;
  background: #0794c9;
  top: 50%;
}

.blog-head h6:after {
  right: 100%;
}

.blog-head h6:before {
  left: 100%;
}
</style>