<template>
  <div>
    <div class="middle">
      <router-link to="/upwork" target="_blank" class="btn">
        <img src="@/assets/image/upwork.png" alt="upwork" />
      </router-link>
      <router-link to="/linkedin" target="_blank" class="btn">
        <img src="@/assets/image/linkedin.png" alt="linkedin" />
      </router-link>
      <router-link to="/github" target="_blank" class="btn">
        <img src="@/assets/image/github-logo.png" alt="github" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.btn {
  width: 50px;
  height: 50px;
  margin: 5px;
  margin-top: 10px;
  color: #3498db;
  overflow: hidden;
  position: relative;
  border-radius: 30%;
  background: #f1f1f1;
  text-decoration: none;
  box-shadow: 0 5px 15px -5px #00000070;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  img {
    font-size: 26px;
    transition: 0.2s linear;
  }
  &:before {
    content: "";
    width: 120%;
    height: 120%;
    left: -110%;
    top: 90%;
    position: absolute;
    background: #3498db;
    transform: rotate(45deg);
    background-image: radial-gradient(
      circle farthest-corner at 10% 20%,
      #56ccf2 0%,
      #f2f2f2 100%
    );
  }
  &:hover {
    img {
      color: #f1f1f1;
      transform: scale(1.3);
    }
    &:before {
      top: -10%;
      left: -10%;
      animation: animation1 0.7s 1;
    }
  }
}

@keyframes animation1 {
  0% {
    top: 90%;
    left: -110%;
  }

  50% {
    top: -30%;
    left: 10%;
  }

  100% {
    top: -10%;
    left: -10%;
  }
}
</style>