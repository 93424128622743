<template>
  <div>
    <section class="skills">
      <div class="blog-head text-center">
        <h6>My Skill Set</h6>
        <h2>Equipped with the knowledge to turn challenges into innovations</h2>
      </div>
      <div class="container">
        <div class="skills-flex-container">
          <div class="logos-wrapper">
            <div class="box" v-for="(logo, index) in logos" :key="index">
              <div class="image">
                <img :src="logo.image" :alt="logo.alt" />
              </div>
              <div class="title">{{ logo.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "skill-page",
  data() {
    return {
      logos: [
        {
          image:
            "https://raw.githubusercontent.com/Smit-Prajapati/prajapatismit/main/images/js.png",
          alt: "JavaScript",
          title: "JavaScript",
        },
        {
          image: "/assets/typescript.svg",
          alt: "TypeScript",
          title: "TypeScript",
        },
        {
          image: "/assets/python1.png",
          alt: "Python",
          title: "Python",
        },
        {
          image: "/assets/Vue.js_Logo_2.svg.png",
          alt: "Vue.js",
          title: "Vue.js",
        },
        {
          image: "/assets/nuxt-js-icon.webp",
          alt: "Nuxt.js",
          title: "Nuxt.js",
        },
        {
          image: "/assets/angular-icon-1-logo-png-transparent.png",
          alt: "Angular",
          title: "Angular",
        },
        { image: "/assets/react.png", alt: "React", title: "React" },
        {
          image: "/assets/svelte.png",
          alt: "Svelte",
          title: "Svelte",
        },
        {
          image: "/assets/icons8-ionic-48.png",
          alt: "Ionic",
          title: "Ionic",
        },
        {
          image: "/assets/Tailwind_CSS_Logo.svg.png",
          alt: "Tailwind CSS",
          title: "Tailwind CSS",
        },
        {
          image: "/assets/mysql.png",
          alt: "MySQL",
          title: "MySQL",
        },
        // {
        //   image: "/assets/mongodb.png",
        //   alt: "MongoDB",
        //   title: "MongoDB",
        // },
        {
          image: "/assets/laravel-icon-497x512-uwybstke.png",
          alt: "Laravel",
          title: "Laravel",
        },
        {
          image: "/assets/symfony-logo-png-transparent.png",
          alt: "Symfony",
          title: "Symfony",
        },
        {
          image: "/assets/firebase.png",
          alt: "Firebase",
          title: "Firebase",
        },

        // new
        {
          image:
            "https://raw.githubusercontent.com/Smit-Prajapati/prajapatismit/main/images/js.png",
          alt: "JavaScript",
          title: "JavaScript",
        },
        {
          image: "/assets/typescript.svg",
          alt: "TypeScript",
          title: "TypeScript",
        },
        {
          image: "/assets/python1.png",
          alt: "Python",
          title: "Python",
        },

        {
          image: "/assets/Vue.js_Logo_2.svg.png",
          alt: "Vue.js",
          title: "Vue.js",
        },
        {
          image: "/assets/nuxt-js-icon.webp",
          alt: "Nuxt.js",
          title: "Nuxt.js",
        },
        {
          image: "/assets/angular-icon-1-logo-png-transparent.png",
          alt: "Angular",
          title: "Angular",
        },
        {
          image: "/assets/svelte.png",
          alt: "Svelte",
          title: "Svelte",
        },
        { image: "/assets/react.png", alt: "React", title: "React" },
        {
          image: "/assets/icons8-ionic-48.png",
          alt: "Ionic",
          title: "Ionic",
        },
        {
          image: "/assets/Tailwind_CSS_Logo.svg.png",
          alt: "Tailwind CSS",
          title: "Tailwind CSS",
        },
        {
          image: "/assets/mysql.png",
          alt: "MySQL",
          title: "MySQL",
        },
        // {
        //   image: "/assets/mongodb.png",
        //   alt: "MongoDB",
        //   title: "MongoDB",
        // },
        {
          image: "/assets/laravel-icon-497x512-uwybstke.png",
          alt: "Laravel",
          title: "Laravel",
        },
        {
          image: "/assets/symfony-logo-png-transparent.png",
          alt: "Symfony",
          title: "Symfony",
        },
        {
          image: "/assets/firebase.png",
          alt: "Firebase",
          title: "Firebase",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$main-color: #002733;
$dark-color: #001925;
$light-color: #87a4b6;
$medium-light-color: #013747;

img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.container {
  margin: 0px auto 0px auto;
  padding: 0px 40px;

  @media (min-width: 1200px) and (max-width: 1441px) {
    max-width: 1250px;
    padding: 0px 36px;
    flex: 1 0 25%;
  }
  @media (max-width: 767px) {
    padding: 0px 30px;
    flex: 1 0 25%;
  }
  @media (max-width: 479px) {
    padding: 0px 30px 0px 20px;
  }
}

.skills {
  padding: 10px 0px;

  .skills-flex-container {
    display: flex;
    width: 100%;

    .logos-wrapper {
      display: flex;
      width: 100%;

      overflow: hidden;
      gap: 2em;
    }

    .box {
      background: $dark-color;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 24px;
      padding: 0.5rem 2rem;
      text-align: center;
      border: 10px solid $medium-light-color;
      animation: scroll 15s linear infinite;

      .image {
        img {
          width: 5rem;
          height: 5rem;
          object-fit: contain;
          max-width: 12rem;
        }
      }

      .title {
        margin-top: 15px;
        text-align: center;
        font-weight: bold;
        color: white;
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.5) 20px 20px 30px;
        transform: scale(1);

        .image {
          transform: scale(1) translateY(-20px);
          img {
            animation: bouncing 0.5s 0.3s;
          }
        }
        .title {
          letter-spacing: 2px;
        }
      }

      @media (max-width: 1100px) {
        flex: 1 0 25%;
        .image {
          img {
            max-width: 50px;
          }
        }
      }
      @media (max-width: 630px) {
        flex: 1 0 35%;
        padding: 15px;
        .image {
          img {
            max-width: 50px;
          }
        }
      }
    }
  }
}

@keyframes bouncing {
  from,
  to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}
.blog-head {
  margin-bottom: 5vh;
}

.blog-head h6 {
  color: #0794c9;
  position: relative;
  display: inline-block;
  text-transform: capitalize;
  font-size: 26px;
  padding: 1rem;
}
.blog-head h2 {
  color: #f6b50e;
  font-size: 17px;
}

.blog-head h6:after,
.blog-head h6:before {
  position: absolute;
  content: "";
  width: 50%;
  height: 3px;

  background: #0794c9;
  top: 50%;
}

.blog-head h6:after {
  right: 100%;
}

.blog-head h6:before {
  left: 100%;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-1000%);
  }
}
</style>
