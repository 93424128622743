<template>
  <div class="footer-section">
    <div class="container">
      <div class="footer-cta pt-5">
        <div class="row">
          <div class="text-center text-lg-left">
            <div class="contact-text">
              <h1>Contact Me</h1>
              <p>
                Let’s chat, tell me about the project and the required services.
              </p>
              <div class="rounded-social-buttons">
                <router-link to="/upwork">
                  <img src="@/assets/image/upwork.png" alt="upwork" />
                </router-link>
                <router-link to="/linkedin" target="_blank">
                  <img src="@/assets/image/linkedin.png" alt="linkedin" />
                </router-link>
                <router-link to="/github" target="_blank">
                  <img src="@/assets/image/github-logo.png" alt="github" />
                </router-link>

                <span>
                  <a class="mailto" href="mailto:bethlehem.danachew@gmail.com"
                    ><img src="@/assets/image/google.png" alt="google" /></a
                ></span>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-4 mb-30">
            <div class="single-cta">
              <i class="fa fa-map-marker"></i>
              <div class="cta-text">
                <h4>Location</h4>
                <span> Addis Ababa, Ethiopia </span>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 mb-30">
            <div class="single-cta">
              <!-- <i class="fa fa-phone" ></i> -->
              <div class="cta-text">
                <h4>Phone</h4>
                <span><a :href="`tel:+2519 20564045`">+2519 20564045</a> </span>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 mb-30">
            <div class="single-cta">
              <i class="fa fa-envelope-open"></i>
              <div class="cta-text">
                <h4>E-mail</h4>
                <span>
                  <a class="mailto" href="mailto:bethlehem.danachew@gmail.com"
                    >bethlehem.danachew@gmail.com</a
                  ></span
                >
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="copyright-area">
      <div class="container">
        <div class="row">
          <div class="text-center text-lg-left">
            <div class="copyright-text">
              <p>
                Copyright &copy; 2023, All Right Reserved | Bethelehem Mulugeta
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-page",
};
</script>

<style lang="scss" scoped>
.footer-section {
  background: rgba(255, 255, 255, 0.25);
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}
.footer-cta {
  border-bottom: 1px solid #6acde4;
  padding-bottom: 8px !important;
}
.single-cta i {
  color: #6acde4;
  font-size: 30px;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #6acde4;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #ffff;
  font-size: 15px;
  display: inline-block;
}
.cta-text span a {
  color: #fff;
  text-decoration: none;
}

.copyright-area {
  background: rgba(255, 255, 255, 0.25);
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #fff;
}
.contact-text {
  margin-bottom: 65px;
}
.contact-text p {
  font-size: 15px;
  color: #fff;
  margin-bottom: 35px;
}
.contact-text h1 {
  font-size: 25px;
  color: #6acde4;
  margin-bottom: 5px;
}
.copyright-text p a {
  color: #6acde4;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a {
  color: #6acde4;
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

.rounded-social-buttons {
  text-align: center;
}
.rounded-social-buttons img {
  text-align: center;
  height: 35px;
  width: 35px;
  padding: 5px;
}
</style>