<template>
  <div>
    <div class="main-part">
      <div class="blog-head text-center">
        <h6>Achievements & Awards</h6>
        <h2>Recognitions, Awards, and Career Milestones</h2>
      </div>
      <div class="image-card-container">
        <div
          class="image-card"
          v-for="(image, index) in imageList"
          :key="index"
        >
          <img :src="image.src" alt="Image" />
          <!-- <div class="image-card-caption">{{ image.caption }}</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "partner-logo",
  data() {
    return {
      imageList: [
        {
          src: require("@/assets/image/photo_2023-04-19 22.32.07.jpeg"),
          caption: "Image 1",
        },
        {
          src: require("@/assets/image/photo_2023-04-19 22.32.11.jpeg"),
          caption: "Image 1",
        },
        { src: require("@/assets/image/isc2.png"), caption: "Image 1" },
        {
          src: require("@/assets/image/photo_2023-04-19 22.31.44.jpeg"),
          caption: "Image 1",
        },
        {
          src: require("@/assets/image/photo_2023-04-19 22.31.50.jpeg"),
          caption: "Image 1",
        },
        {
          src: require("@/assets/image/photo_2023-04-19 22.31.56.jpeg"),
          caption: "Image 1",
        },
        { src: require("@/assets/image/ibm.png"), caption: "Image 2" },
        {
          src: require("@/assets/image/photo_2023-04-19 22.32.02.jpeg"),
          caption: "Image 3",
        },
        {
          src: require("@/assets/image/isc-candidate.png"),
          caption: "Image 4",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.image-card-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
  gap: 20px;
}
.image-card {
  flex: 0 0 auto;
  width: 300px;

  scroll-snap-align: start;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.image-card img {
  max-width: 100%;
  height: 15rem;
}
.image-card-caption {
  padding: 10px;
  background-color: #f0f0f0;
  font-size: 14px;
  text-align: center;
}

.main-part {
  margin-top: 50px;
}
.blog-head {
  margin-bottom: 5vh;
}

.blog-head h6 {
  color: #0794c9;
  position: relative;
  display: inline-block;
  text-transform: capitalize;
  font-size: 26px;
}
.blog-head h2 {
  color: #f6b50e;
  font-size: 17px;
}

.blog-head h6:after,
.blog-head h6:before {
  position: absolute;
  content: "";
  width: 30px;
  height: 3px;
  background: #0794c9;
  top: 50%;
}

.blog-head h6:after {
  right: 100%;
}

.blog-head h6:before {
  left: 100%;
}
</style>