<template>
  <div class="main-landing">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="inner-column">
            <div class="sec-title">
              <span class="title">Absolute coding at its finest </span>
              <h2>
                I am
                <span class="typed-text">{{ typeValue }}</span>
                <span class="cursor" :class="{ typing: typeStatus }"
                  >&nbsp;</span
                >
              </h2>
            </div>
            <div class="text-des">
              👋 I am Bethelehem <br />
              High-Skilled Full Stack Developer | Experienced Vue js Expert |
              Experienced Nuxt js Developer | Experienced Ionic Developer | Web
              Developer | Mobile App Developer<br /><br />
              I have worked on a wide variety of challenging projects requiring
              different skills and I have excelled at them. Now, I make things
              happen seamlessly and I have become someone you can rely on for
              your Web and App Development.
            </div>

            <div class="btn-box">
              <!-- <a class="mailto" href="mailto:bethlehem.danachew@gmail.com">Hire Me</a> -->
              <a class="mailto" href="mailto:bethlehem.danachew@gmail.com"
                ><button class="theme-btn1 btn-style-one">Hire Me</button></a
              >
              <!-- <SocialMedia /> -->
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <section class="integration-section">
            <!-- <div class="bubbles">
              <img
                src="@/assets/image/icons8-nuxt-js-a-free-and-open-source-web-application-framework-36.png"
                alt="bubbles"
              />
              <img src="@/assets/image/vuejs.webp" alt="bubbles" />
              <img src="@/assets/image/icons8-figma-48.png" alt="bubbles" />
              <img src="@/assets/image/icons8-angularjs-48.png" alt="bubbles" />
              <img src="@/assets/image/icons8-laravel-64.png" alt="bubbles" />
              <img src="@/assets/image/icons8-node-js-48.png" alt="bubbles" />
              <img src="@/assets/image/icons8-ionic-48.png" alt="bubbles" />
            </div> -->
            <!-- <img src="@/assets/image/Application.svg" alt="Hero section" /> -->
            <!-- <div class="container">
              <div class="row">
                <div
                  class="col-lg-6 col-md-12 col-sm-12 integration-column offset-lg-3"
                >
                  <div class="integrations-block wow zoomIn animated animated">
                    <div class="icon-box">
                      <div class="icon icon-1">
                        <img src="@/assets/image/vuejs.webp" alt="vue js" />
                      </div>
                      <div class="icon icon-2">
                        <img
                          src="@/assets/image/icons8-nuxt-js-a-free-and-open-source-web-application-framework-36.png"
                          alt=""
                        />
                      </div>
                      <div class="icon icon-3">
                        <img
                          src="@/assets/image/icons8-ionic-48.png"
                          alt="icon"
                        />
                      </div>
                      <div class="icon icon-4">
                        <img src="@/assets/image/icons8-node-js-48.png" />
                      </div>
                      <div class="icon icon-5">
                        <img
                          src="@/assets/image/icons8-laravel-64.png"
                          alt="laravel"
                        />
                      </div>
                      <div class="icon icon-6">
                        <img
                          src="@/assets/image/icons8-angularjs-48.png"
                          alt=""
                        />
                      </div>
                      <div class="icon icon-7">
                        <img src="@/assets/image/icons8-figma-48.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SocialMedia from "./SocialMedia.vue";

export default {
  name: "landing-page",
  data: () => {
    return {
      typeValue: "",
      typeStatus: false,
      typeArray: ["Full-Stack Developer", "UX/UI Designer", "AI Developer"],
      typingSpeed: 200,
      erasingSpeed: 100,
      newTextDelay: 2000,
      typeArrayIndex: 0,
      charIndex: 0,
    };
  },
  components: {
    // SocialMedia,
  },
  methods: {
    typeText() {
      if (this.charIndex < this.typeArray[this.typeArrayIndex].length) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue += this.typeArray[this.typeArrayIndex].charAt(
          this.charIndex
        );
        this.charIndex += 1;
        setTimeout(this.typeText, this.typingSpeed);
      } else {
        this.typeStatus = false;
        setTimeout(this.eraseText, this.newTextDelay);
      }
    },
    eraseText() {
      if (this.charIndex > 0) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue = this.typeArray[this.typeArrayIndex].substring(
          0,
          this.charIndex - 1
        );
        this.charIndex -= 1;
        setTimeout(this.eraseText, this.erasingSpeed);
      } else {
        this.typeStatus = false;
        this.typeArrayIndex += 1;
        if (this.typeArrayIndex >= this.typeArray.length)
          this.typeArrayIndex = 0;
        setTimeout(this.typeText, this.typingSpeed + 1000);
      }
    },
  },
  created() {
    setTimeout(this.typeText, this.newTextDelay + 200);
  },
};
</script>

<style lang="scss" scoped>
/** integration-section **/

.main-landing {
  height: 100%;
  background-image: linear-gradient(to left, rgba(114, 119, 119, 0.62), #314c52),
    url("@/assets/image/hero2.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.integration-section {
  position: relative;
  display: block;
  padding: 70px 0px 120px 0px;
  margin-left: -10vh;
  animation: MyDiv;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  animation-direction: alternate-reverse;
}

@keyframes MyDiv {
  0% {
    left: 0px;
    top: 0px;
  }
  100% {
    left: 10px;
    top: 0px;
  }
}
.integration-section img {
  align-items: center;
  width: 80%;
  height: 80%;
}
/*
.integration-section .integrations-block {
  position: relative;
  height: 550px;
  width: 70vh;
  margin-left: -30px;
  background-image: url(@/assets/image/integration-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}

.integration-section .integrations-block .icon {
  position: absolute;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.integration-section .integrations-block .icon a,
.integration-section .integrations-block .icon img {
  position: relative;
  display: inline-block;
}

.integration-section .integrations-block .icon img {
  border-radius: 50%;
  box-shadow: 0 23px 50px #d9d9e7;
  background-color: #fff;
  width: 5rem;
}

.integration-section .integrations-block .icon-1 {
  left: 184px;
  top: -46px;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.integration-section .integrations-block .icon-2 {
  left: 5%;
  top: 10%;
  -webkit-animation: zoom-fade-two 5s infinite linear;
  animation: zoom-fade-two 5s infinite linear;
}

.integration-section .integrations-block .icon-3 {
  right: 120px;
  top: 15%;
  -webkit-animation: zoom-fade-two 5s infinite linear;
  animation: zoom-fade-two 5s infinite linear;
}

.integration-section .integrations-block .icon-4 {
  left: 10px;
  bottom: 10vh;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.integration-section .integrations-block .icon-5 {
  right: 45%;
  bottom: 10vh;
  -webkit-animation: zoom-fade-two 5s infinite linear;
  animation: zoom-fade-two 5s infinite linear;
}

.integration-section .integrations-block .icon-6 {
  right: 4%;
  bottom: 10%;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}
.integration-section .integrations-block .icon-7 {
  right: 78%;
  top: 40%;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

*/
.inner-column {
  margin-top: 15vh;
  text-align: start;
  position: relative;
}

img {
  max-width: 100%;
  height: auto;
}
section {
  padding: 60px 0;
  /* min-height: 100vh;*/
}

.sec-title {
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
}

.sec-title .title {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #00aeef;
  font-weight: 500;
  margin-bottom: 15px;
}
h2 {
  font-size: 6rem;
  font-weight: normal;
  span.typed-text {
    color: #0794c9;
  }
  span.cursor {
    display: inline-block;
    margin-left: 3px;
    width: 4px;
    background-color: #fff;
    animation: cursorBlink 1s infinite;
  }
  span.cursor.typing {
    animation: none;
  }
}
@keyframes cursorBlink {
  49% {
    background-color: #fff;
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
}

.sec-title h2 {
  position: relative;
  display: block;
  font-size: 40px;
  line-height: 1.28em;
  color: #fff;
  font-weight: 600;
  padding-bottom: 18px;
}

.sec-title h2:before {
  position: absolute;
  content: "";
  left: 0px;
  bottom: 0px;
  width: 50px;
  height: 3px;
  background-color: #d1d2d6;
}

.text-des {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  color: #ffff;
  font-weight: 400;
  margin-top: 35px;
}

.light h2 {
  color: #ffffff;
}

.text-center h2:before {
  left: 50%;
  margin-left: -25px;
}
.btn-box {
  display: flex;
  padding-bottom: 3rem;
}
.theme-btn1 {
  margin: 10px 1px 10px;
}
.theme-btn2 {
  margin: 10px 10px 10px;
}
.btn-style-one {
  position: relative;
  display: flex;
  font-size: 17px;
  line-height: 30px;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  padding: 10px 20px;
  font-weight: 600;
  overflow: hidden;
  letter-spacing: 0.02em;
  background-color: #00aeef;
}

.btn-style-one:hover {
  background-color: #0794c9;
  color: #ffffff;
}
.btn-style-two {
  position: relative;
  display: inline-block;
  font-size: 17px;
  line-height: 30px;
  border: none;
  border-radius: 10px;
  color: #00aeef;
  padding: 10px 30px;
  font-weight: 600;
  overflow: hidden;
  letter-spacing: 0.02em;
  background-color: #fff;
  border: 4px solid #00aeef;
}

.btn-style-two:hover {
  background-color: #0794c9;
  color: #ffffff;
  border: 4px solid #fff;
}
@media screen and (max-width: 767px) {
  .integration-section {
    padding: 30px 0;
    display: none;
  }
}
/*
  .integration-section .integrations-block {
    max-width: 100%;
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .integration-section .integrations-block .icon img {
    width: 3rem;
    display: none;
  }
}

@media screen and (max-width: 320px) {
  .integration-section .integrations-block {
    display: none;
  }
}
@media (min-width: 320px) {
  .integration-section .integrations-block {
    position: relative;
    height: 50vh;
    width: 40vh;
    margin-left: 15%;
    .integration-section .integrations-block {
      display: none;
    }
  }

  .integration-section .integrations-block .icon img {
    border-radius: 50%;
    box-shadow: 0 23px 50px #d9d9e7;
    background-color: #fff;
    width: 3rem;
  }

  .integration-section .integrations-block .icon-1 {
    left: 184px;
    top: -46px;
  }

  .integration-section .integrations-block .icon-2 {
    left: 5%;
    top: 10%;
  }

  .integration-section .integrations-block .icon-3 {
    right: 10px;
    top: 15%;
  }

  .integration-section .integrations-block .icon-4 {
    left: 1px;
    bottom: 5vh;
  }

  .integration-section .integrations-block .icon-5 {
    right: 45%;
    top: 45vh;
  }

  .integration-section .integrations-block .icon-6 {
    right: 4%;
    bottom: 10%;
  }
  .integration-section .integrations-block .icon-7 {
    right: 58%;
    top: -10%;
  }
}
@media (min-width: 481px) {

  .integration-section .integrations-block .icon img {
    border-radius: 50%;
    box-shadow: 0 23px 50px #d9d9e7;
    background-color: #fff;
    width: 3rem;
  }

  .integration-section .integrations-block .icon-1 {
    left: 184px;
    top: -46px;
  }

  .integration-section .integrations-block .icon-2 {
    left: 5%;
    top: 10%;
  }

  .integration-section .integrations-block .icon-3 {
    right: 10px;
    top: 15%;
  }

  .integration-section .integrations-block .icon-4 {
    left: -9px;
    bottom: 5vh;
  }

  .integration-section .integrations-block .icon-5 {
    right: 45%;
    top: 55vh;
  }

  .integration-section .integrations-block .icon-6 {
    right: 4%;
    bottom: 10%;
  }
  .integration-section .integrations-block .icon-7 {
    right: 58%;
    top: -10%;
  }
}
@media (min-width: 641px) {
}
@media (min-width: 961px) {
}
@media (max-width: 1200px) {
}
@media screen and (max-width: 890px) {
}

@media (max-width: 992px) {
}
@media (min-width: 1025px) {
  .integration-section .integrations-block {
    position: relative;
    height: 550px;
    width: 70vh;
    margin-left: -30px;
    background-image: url(@/assets/image/integration-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    z-index: 1;
  }

  .integration-section .integrations-block .icon {
    position: absolute;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
  }

  .integration-section .integrations-block .icon a,
  .integration-section .integrations-block .icon img {
    position: relative;
    display: inline-block;
  }

  .integration-section .integrations-block .icon img {
    border-radius: 50%;
    box-shadow: 0 23px 50px #d9d9e7;
    background-color: #fff;
    width: 5rem;
  }

  .integration-section .integrations-block .icon-1 {
    left: 184px;
    top: -46px;
    -webkit-animation: zoom-fade 5s infinite linear;
    animation: zoom-fade 5s infinite linear;
  }

  .integration-section .integrations-block .icon-2 {
    left: 5%;
    top: 10%;
    -webkit-animation: zoom-fade-two 5s infinite linear;
    animation: zoom-fade-two 5s infinite linear;
  }

  .integration-section .integrations-block .icon-3 {
    right: 120px;
    top: 15%;
    -webkit-animation: zoom-fade-two 5s infinite linear;
    animation: zoom-fade-two 5s infinite linear;
  }

  .integration-section .integrations-block .icon-4 {
    left: 10px;
    bottom: 10vh;
    -webkit-animation: zoom-fade 5s infinite linear;
    animation: zoom-fade 5s infinite linear;
  }

  .integration-section .integrations-block .icon-5 {
    right: 45%;
    bottom: 10vh;
    -webkit-animation: zoom-fade-two 5s infinite linear;
    animation: zoom-fade-two 5s infinite linear;
  }

  .integration-section .integrations-block .icon-6 {
    right: 4%;
    bottom: 10%;
    -webkit-animation: zoom-fade 5s infinite linear;
    animation: zoom-fade 5s infinite linear;
  }
  .integration-section .integrations-block .icon-7 {
    right: 78%;
    top: 40%;
    -webkit-animation: zoom-fade 5s infinite linear;
    animation: zoom-fade 5s infinite linear;
  }
}
@media (min-width: 1281px) {
  .integration-section .integrations-block {
    position: relative;
    height: 550px;
    width: 70vh;
    margin-left: -30px;
    background-image: url(@/assets/image/integration-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
  }

  .integration-section .integrations-block .icon {
    position: absolute;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
  }

  .integration-section .integrations-block .icon a,
  .integration-section .integrations-block .icon img {
    position: relative;
    display: inline-block;
  }

  .integration-section .integrations-block .icon img {
    border-radius: 50%;
    box-shadow: 0 23px 50px #d9d9e7;
    background-color: #fff;
    width: 5rem;
  }

  .integration-section .integrations-block .icon-1 {
    left: 184px;
    top: -46px;
    -webkit-animation: zoom-fade 5s infinite linear;
    animation: zoom-fade 5s infinite linear;
  }

  .integration-section .integrations-block .icon-2 {
    left: 5%;
    top: 10%;
    -webkit-animation: zoom-fade-two 5s infinite linear;
    animation: zoom-fade-two 5s infinite linear;
  }

  .integration-section .integrations-block .icon-3 {
    right: 120px;
    top: 15%;
    -webkit-animation: zoom-fade-two 5s infinite linear;
    animation: zoom-fade-two 5s infinite linear;
  }

  .integration-section .integrations-block .icon-4 {
    left: 10px;
    bottom: 10vh;
    -webkit-animation: zoom-fade 5s infinite linear;
    animation: zoom-fade 5s infinite linear;
  }

  .integration-section .integrations-block .icon-5 {
    right: 45%;
    bottom: 10vh;
    -webkit-animation: zoom-fade-two 5s infinite linear;
    animation: zoom-fade-two 5s infinite linear;
  }

  .integration-section .integrations-block .icon-6 {
    right: 4%;
    bottom: 10%;
    -webkit-animation: zoom-fade 5s infinite linear;
    animation: zoom-fade 5s infinite linear;
  }
  .integration-section .integrations-block .icon-7 {
    right: 78%;
    top: 40%;
    -webkit-animation: zoom-fade 5s infinite linear;
    animation: zoom-fade 5s infinite linear;
  }
}*/
</style>