<template>
  <div class="main-landing">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="inner-column">
            <div class="sec-title">
              <h2>
                I'M
                <span class="typed-text">{{ typeValue }}</span>
                <span class="cursor" :class="{ typing: typeStatus }"
                  >&nbsp;</span
                >
              </h2>
            </div>
            <div class="text-des">
              <span class="title"
                >Design. <span class="develop">Develop.</span> Deliver.
              </span>
              Welcome! Make yourself at home as you explore. I'm a highly
              skilled Full Stack and Mobile App developer with a proven track
              record of tackling diverse and challenging projects. I excel in
              delivering seamless solutions tailored to your needs. From sleek
              mobile apps to robust web solutions, count on me as your trusted
              partner for Web and App Development.
            </div>

            <div class="btn-box">
              <!-- <a class="mailto" href="mailto:bethlehem.danachew@gmail.com">Hire Me</a> -->
              <a class="mailto" href="mailto:bethlehem.danachew@gmail.com">
                <button class="button">
                  <span class="text">Contact</span>
                  <div class="overlay">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="lucide lucide-arrow-right"
                    >
                      <path d="M5 12h14" />
                      <path d="m12 5 7 7-7 7" />
                    </svg>
                  </div></button
              ></a>
              <SocialMedia />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <section class="integration-section"></section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialMedia from "./SocialMedia.vue";

export default {
  name: "landing-page",
  data: () => {
    return {
      typeValue: "",
      typeStatus: false,
      typeArray: [
        "Full-Stack Developer",
        "Mobile App Developer",
        "Computer Engineer",
      ],
      typingSpeed: 200,
      erasingSpeed: 100,
      newTextDelay: 2000,
      typeArrayIndex: 0,
      charIndex: 0,
    };
  },
  components: {
    SocialMedia,
  },
  methods: {
    typeText() {
      if (this.charIndex < this.typeArray[this.typeArrayIndex].length) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue += this.typeArray[this.typeArrayIndex].charAt(
          this.charIndex
        );
        this.charIndex += 1;
        setTimeout(this.typeText, this.typingSpeed);
      } else {
        this.typeStatus = false;
        setTimeout(this.eraseText, this.newTextDelay);
      }
    },
    eraseText() {
      if (this.charIndex > 0) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue = this.typeArray[this.typeArrayIndex].substring(
          0,
          this.charIndex - 1
        );
        this.charIndex -= 1;
        setTimeout(this.eraseText, this.erasingSpeed);
      } else {
        this.typeStatus = false;
        this.typeArrayIndex += 1;
        if (this.typeArrayIndex >= this.typeArray.length)
          this.typeArrayIndex = 0;
        setTimeout(this.typeText, this.typingSpeed + 1000);
      }
    },
  },
  created() {
    setTimeout(this.typeText, this.newTextDelay + 200);
  },
};
</script>

<style lang="scss" scoped>
/** integration-section **/

.main-landing {
  height: 100%;
  background-image: linear-gradient(to left, rgba(114, 119, 119, 0.52), #314c52),
    url("@/assets/image/service/herosection.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.integration-section {
  position: relative;
  display: block;
  padding: 70px 0px 120px 0px;
  margin-left: -10vh;
  animation: MyDiv;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  animation-direction: alternate-reverse;
}

@keyframes MyDiv {
  0% {
    left: 0px;
    top: 0px;
  }
  100% {
    left: 10px;
    top: 0px;
  }
}
.integration-section img {
  align-items: center;
  width: 80%;
  height: 80%;
}

.inner-column {
  margin-top: 15vh;
  text-align: start;
  position: relative;
}

img {
  max-width: 100%;
  height: auto;
}
section {
  padding: 60px 0;
  /* min-height: 100vh;*/
}

.sec-title {
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
}

.text-des .title {
  position: relative;
  display: block;
  font-size: 2rem;
  color: #00aeef;
  font-weight: 500;
  margin-bottom: 15px;
}
.text-des .title .develop {
  color: #a7e2f8;
}
h2 {
  font-size: 6rem;
  font-weight: normal;
  span.typed-text {
    color: #0794c9;
  }
  span.cursor {
    display: inline-block;
    margin-left: 3px;
    width: 4px;
    background-color: #fff;
    animation: cursorBlink 1s infinite;
  }
  span.cursor.typing {
    animation: none;
  }
}
@keyframes cursorBlink {
  49% {
    background-color: #fff;
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
}

.sec-title h2 {
  position: relative;
  display: block;
  font-size: 40px;
  line-height: 1.28em;
  color: #fff;
  font-weight: 600;
  padding-bottom: 18px;
}

.sec-title h2:before {
  position: absolute;
  content: "";
  left: 0px;
  bottom: 0px;
  width: 50px;
  height: 3px;
  background-color: #d1d2d6;
}

.text-des {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  color: #ffff;
  font-weight: 400;
  margin-top: 35px;
}

.light h2 {
  color: #ffffff;
}

.text-center h2:before {
  left: 50%;
  margin-left: -25px;
}
.btn-box {
  display: flex;
  padding-bottom: 3rem;
}
.theme-btn1 {
  margin: 10px 20px 10px;
}
.theme-btn2 {
  margin: 10px 10px 10px;
}
.btn-style-one {
  position: relative;
  display: flex;
  font-size: 17px;
  line-height: 30px;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  padding: 10px 20px;
  font-weight: 600;
  overflow: hidden;
  letter-spacing: 0.02em;
  background-color: #00aeef;
}

.btn-style-one:hover {
  background-color: #0794c9;
  color: #ffffff;
}
.btn-style-two {
  position: relative;
  display: inline-block;
  font-size: 17px;
  line-height: 30px;
  border: none;
  border-radius: 10px;
  color: #00aeef;
  padding: 10px 30px;
  font-weight: 600;
  overflow: hidden;
  letter-spacing: 0.02em;
  background-color: #fff;
  border: 4px solid #00aeef;
}

.btn-style-two:hover {
  background-color: #0794c9;
  color: #ffffff;
  border: 4px solid #fff;
}
@media screen and (max-width: 767px) {
  .integration-section {
    padding: 30px 0;
    display: none;
  }
}

.button {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  width: 10rem;
  border-radius: 9999rem;
  background: #00aeef;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  isolation: isolate;
  overflow: hidden;
  margin: 10px 1px 10px;

  & > span.text {
    color: #ffffff;
    width: 100%;
    text-align: left;
    padding-block: 12px;
    padding-left: 24px;
    z-index: 2;
    transition: all 200ms ease;
  }

  & > div.overlay {
    color: #ededed;
    width: 48px;
    height: calc(100% - 2px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 12px;
    border-radius: inherit;
    background: #187293;
    position: absolute;
    right: 1px;
    z-index: 1;
    transition: all 400ms ease;
  }

  &:is(:hover, :focus) {
    & > span.text {
      color: #ededed;
    }
    & > div.overlay {
      width: calc(100% - 2px);
      transition: width 200ms ease;
    }
  }
}
</style>