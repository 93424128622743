<template>
  <div class="services-cul" id="about">
    <div class="text-center pos-r">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-10 ml-auto mr-auto">
            <div class="align-center">
              <div class="section-title">
                <div class="title-effect">
                  <div class="bar bar-top"></div>
                  <div class="bar bar-right"></div>
                  <div class="bar bar-bottom"></div>
                  <div class="bar bar-left"></div>
                </div>
                <h6>About Me</h6>
                <h2 class="title" style="color: #f6b50e; font-size: 17px">
                  Turning ideas into clean code and problems into innovative
                  solutions.
                </h2>
              </div>
            </div>
          </div>
          <div class="services-cul">
            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <img
                    src="@/assets/image/Codetyping.svg"
                    alt="project thumbnail"
                    class="project-thumbnail"
                  />
                </div>
                <div class="col-md-6">
                  <div class="project-name">
                    <h2 class="project-title">This is me</h2>
                    <p class="slogens">
                      Experienced Designer & Developer who can provide your
                      business a new creative start right a Way!
                    </p>
                    <p class="project-description">
                      I am a passionate, detail-oriented & committed individual
                      with strong problem-solving capability, time management
                      skills, and organizational skills.
                      <br /><br />
                      I have successfully designed, developed, and deployed
                      various mobile and web applications, following clean
                      coding practices in a test-driven development framework.
                      With a deep passion for and top-notch expertise in web and
                      app development, I am well-prepared to be a valuable asset
                      to your team.
                    </p>
                    <div class="card-flex">
                      <div class="span-item">
                        <p>50+</p>
                        <span>Project Done</span>
                      </div>
                      <div class="span-item">
                        <p>4+</p>
                        <span> Years Experience</span>
                      </div>
                      <div class="span-item">
                        <p>35+</p>
                        <span>Happy Clients</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-6"></div>
        <div class="col-md-6"></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "news-page",
  data() {
    return {};
  },
  components: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
$main-color: #002733;
$dark-color: #001925;
$light-color: #87a4b6;
$medium-light-color: #013747;

.services-cul {
  padding: 30px 0px 0px 0px;
  width: 100%;
  margin-left: -3vh;
}
.client {
  background-color: #87c096;
  border-radius: 10px;
  height: 10vh;
  width: 15vh;
  margin-top: -40vh;
  position: absolute;
}
.client p {
  color: #fff;
  text-align: center;
  font-size: 15px;
}
.client img {
  height: 20px;
}
.experience {
  background-color: #8bc3d7;
  border-radius: 10px;
  height: 10vh;
  width: 15vh;
  margin-top: -7vh;
  margin-left: 30vh;
  position: absolute;
}
.experience p {
  color: #fff;
  text-align: center;
  font-size: 15px;
}
.experience img {
  height: 20px;
}
.complet-project {
  background-color: #e4a190;
  border-radius: 10px;
  height: 10vh;
  width: 15vh;
  margin-top: -50vh;
  margin-left: 30vh;
  position: absolute;
}
.complet-project p {
  color: #fff;
  text-align: center;
  font-size: 15px;
}
.complet-project img {
  height: 20px;
}

h2,
h4 {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  margin-bottom: 10px;
  font-weight: 500;
  color: #fff;
}
.pos-r {
  position: relative;
}
.text-center {
  padding-top: 80px;
}
.section-title {
  margin-bottom: 50px;
  position: relative;
}
.align-center {
  width: 100%;
  margin-top: -4rem;
}
.title {
  color: #f6b50e;
  font-size: 17px;
}
.title h2 {
  color: #f6b50e;
  font-size: 17px;
}
.title-effect {
  align-items: center;
}
.slogens {
  font-size: 23px;
  color: #fff;
  text-align: start;
  line-height: normal;
  font-weight: bold;
}
.section-title p {
  font-size: 16px;
}
.title-effect {
  width: 50px;
  height: 50px;
  top: 0;
  position: absolute;
  left: 0;
  opacity: 0.5;
  color: #fff;
  animation: rotation 12.8s steps(1) 0s infinite;
}
.text-center .title-effect {
  left: 50%;
  margin-left: -25px;
}
.text-center .section-title h6 {
  padding: 15px 0;
  color: #0794c9;
  font-size: 26px;
}

.title-effect .bar {
  background: #0794c9;
}
.mb-0 {
  color: #fff;
}
.title-effect .bar-top {
  width: 100%;
  height: 7px;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: left top;
  transform: scale(0, 1);
  animation: bar-top 3.2s linear 0s infinite;
}
.title-effect .bar-right {
  width: 7px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transform-origin: left top;
  transform: scale(1, 0);
  animation: bar-right 3.2s linear 0s infinite;
}
.title-effect .bar-bottom {
  width: 100%;
  height: 7px;
  position: absolute;
  right: 0;
  bottom: 0;
  transform-origin: right top;
  transform: scale(0, 1);
  animation: bar-bottom 3.2s linear 0s infinite;
}
.title-effect .bar-left {
  width: 7px;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  transform-origin: left bottom;
  transform: scale(1, 0);
  animation: bar-left 3.2s linear 0s infinite;
}

.title {
  position: relative;
  color: #fff;
  margin-bottom: 0;
}
.section-title h2 {
  margin-bottom: 15px;
}
.box-loader {
  border-radius: 100%;
  margin: 0 auto;
  position: absolute;
  top: 15px;
  left: 15px;
}
.box-loader span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #2575fc;
  margin: 0 5px;
  opacity: 0;
}
.box-loader span:nth-child(1) {
  animation: opacitychange 1s ease-in-out infinite;
}
.box-loader span:nth-child(2) {
  animation: opacitychange 1s ease-in-out 0.33s infinite;
}
.box-loader span:nth-child(3) {
  animation: opacitychange 1s ease-in-out 0.66s infinite;
}

/*--rotation--*/

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes bar-top {
  0% {
    transform: scale(0, 1);
  }
  12.5% {
    transform: scale(1, 1);
  }
  87.5% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0, 1);
  }
}
@keyframes bar-right {
  0% {
    transform: scale(1, 0);
  }
  12.5% {
    transform: scale(1, 0);
  }
  25% {
    transform: scale(1, 1);
  }
  75% {
    transform: scale(1, 1);
  }
  87.5% {
    transform: scale(1, 0);
  }
  100% {
    transform: scale(1, 0);
  }
}
@keyframes bar-bottom {
  0% {
    transform: scale(0, 1);
  }
  25% {
    transform: scale(0, 1);
  }
  37.5% {
    transform: scale(1, 1);
  }
  62.5% {
    transform: scale(1, 1);
  }
  75% {
    transform: scale(0, 1);
  }
  100% {
    transform: scale(0, 1);
  }
}
@keyframes bar-left {
  0% {
    transform: scale(1, 0);
  }
  37.5% {
    transform: scale(1, 0);
  }
  50% {
    transform: scale(1, 1);
  }
  62.5% {
    transform: scale(1, 0);
  }
  100% {
    transform: scale(1, 0);
  }
}

/*--opacitychange--*/

@keyframes opacitychange {
  0%,
  100% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
}

/* responsive */
@media (max-width: 1200px) {
  #svg-container {
    width: 85%;
  }
}
@media screen and (max-width: 890px) {
}

@media screen and (max-width: 502px) {
}

@media screen and (max-width: 387px) {
}

@media screen and (max-width: 364px) {
}
@media (max-width: 992px) {
  .md-mt-5 {
    margin-top: 50px !important;
  }
  /*Step*/
  .work-process .box-loader {
    display: none;
  }
  .step-desc p {
    max-width: 300px;
    margin: 0 auto;
  }
  #svg-container {
    width: 100%;
    transform: rotate(90deg);
    left: 0;
    top: 50%;
  }
}

.project-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 5em;
  gap: 4em;
  width: 95%;
}

.project-thumbnail {
  object-fit: cover;
  border-radius: 15px;
  width: 100%;
  height: 25rem;
  margin-left: 10%;
  transition: 0.2s ease;
  transition-property: all;
  transition-property: transform;
}

.project-thumbnail:hover {
  transform: scale(1.03);
}

.project-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10vh;
  text-align: start;
}

.project-title {
  font-size: 1.4rem;
  color: #0794c9;
  align-items: flex-start;
  font-style: italic;
}

.project-description {
  max-width: 100%;
  color: #f2f2f2;

  text-align: start;
  font-size: 15px;
}
.btn-box {
  padding: 10px;
}
.btn-style-one {
  position: relative;
  display: inline-block;
  font-size: 17px;
  line-height: 30px;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  padding: 10px 30px;
  font-weight: 600;
  overflow: hidden;
  letter-spacing: 0.02em;
  background-color: #00aeef;
}

.btn-style-one:hover {
  background-color: #0794c9;
  color: #ffffff;
}
.services-cul {
  padding: 30px 0px 0px 0px;
  width: 100%;
  margin-left: -3vh;
}
.card-flex {
  display: flex;
}
.span-item {
  padding: 4%;
  line-height: 1.25rem;
}
.span-item:hover {
  box-shadow: 0 0 18px 0 rgba(113, 216, 231, 0.8);
  cursor: pointer;
  transform: scale(1.1);
}
.span-item p {
  font-size: 3rem;
  color: #f6b50e;
}
.span-item span {
  font-size: 1rem;
  color: #fff;
}
</style>